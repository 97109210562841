/**
 * Bio component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

function Bio() {
  return (
    <StaticQuery
      query={bioQuery}
      render={data => {
        const { author, social } = data.site.siteMetadata
        return (
          <Container>
            <p>
              By <strong>{author}</strong>. Follow our journey on&nbsp;
              <a target="_blank" href={`https://social.mailpace.com/@paul`}>
                Mastodon
              </a> and&nbsp;
              <a target="_blank" href={`https://twitter.com/${social.twitter}`}>
                Twitter
              </a>, and sign up to our <a target="_blank" href="https://buttondown.email/mailpace/">Product Newsletter</a>.
            </p>
          </Container>
        )
      }}
    />
  )
}

const bioQuery = graphql`
  query BioQuery {
    avatar: file(absolutePath: { regex: "/great.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    site {
      siteMetadata {
        author
        social {
          twitter
        }
      }
    }
  }
`

const Container = styled.div`
  display: flex;
`

export default Bio
